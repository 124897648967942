@use "sass:map";

.header {
  z-index: 11;
  position: fixed;
  background: transparent;
  top: 0;
  height: $header-height;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  transition: background-color .3s;
  padding: 0 50px;

  .header-container {
    max-width: map.get($container-max-widths, fourk);
  }

  .navbar, .header-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-menu {
    display: flex;
    flex: 1;
  }

  .nav-group-menu-sign-in {
    display: none;
    flex: 1;
  }

  .navbar-tools {
    justify-content: flex-end;
  }

  .navbar-menu-mobile {
    display: none;
  }

  .dropdown-toggle {
    &:after {
      opacity: .5;
      content: '\f107';
      font-family: $font-family-icon;
      border: 0;
      margin-left: 6px;
      font-size: 11.2px;
      transition-duration: 0.3s;
      transition-property: transform;
    }

    &:hover:after {
      opacity: 1;
    }

    &:not(.collapsed) {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .language-toggle {
    text-transform: uppercase;
  }

  .logo-container {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-sections {
    flex: 1;
  }

  .logo {
    width: 166.85px;

    .svg-text {
      fill: #fff;
    }

    .svg-icon {
      fill: #fff;
    }
  }

  .navbar-nav > .nav-item {
    margin: 0 3px 0 0;
    padding: 0 3px;

    > .nav-link {
      color: #fff;
    }
  }

  .nav-toggle {
    display: none;
    align-items: center;
    padding: 0 18px;
    background-color: transparent;
    border-color: transparent;
  }

  .nav-hamburger {
    width: 24px;
    height: 16px;
    position: relative;
    transition: .5s ease-in-out;
    cursor: pointer;
    background: transparent;
    border: 0;
    display: block;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: var(--bs-header-color);
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 6px;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 12px;
        transform-origin: left center;
      }

    }

  }

  .nav-separator {
    border-bottom: 1px solid var(--bs-border-color);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .button-op {
    margin: 0 3px 0 10px;
  }

  .nav-group-default {
    display: flex;
  }

  .dropdown-menu {
    border-radius: $border-radius;
    box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 25px;

    .nav-item {
      padding-left: 0;
      margin-left: 0;
    }

    .nav-link {
      width: 100%;
      text-align: left;
      white-space: nowrap;
      display: block;
      padding-left: 0;
      margin-left: 0;
    }

    .nav-link-badge {
      color: #03569D;
      font-size: 0.6em;
      margin-left: 10px;
      font-weight: bold;
      border-radius: 100px;
      border: 2px solid #03569D;
      vertical-align: text-bottom;
      padding: 2px 7px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }

    .nav-link, .nav-link:visited {
      color: var(--bs-dropdown-color);
    }

    .nav-link.active, .nav-link:hover {
      color: var(--dropdown-link-active-color);
    }

    &.show {
      max-height: 800px !important;
      visibility: visible !important;
    }
  }

  .dropdown-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  #header-dropdown-3,
  #header-dropdown-4 {
    .dropdown-col {
      min-width: 200px;
    }
  }

  .dropdown-col + .dropdown-col {
    padding-left: 40px;
  }

  .dropdown-group-header {
    font-family: $font-family-monospace;
    letter-spacing: 1px;
    color: var(--bs-link-hover-color);
    font-size: .8rem;
    font-weight: 400;
    display: flex;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  li.dropdown-group-header {
    margin-top: 10px;
  }

  &.header-white, &.header-scrolled {
    background-color: var(--bs-header-bg-color);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);

    .logo {
      .svg-text {
        fill: var(--bs-header-color);
      }

      .svg-icon {
        fill: #1A67A3;
      }
    }

    .navbar-nav > .nav-item {
      > .nav-link {
        color: var(--bs-header-color);
      }
    }

    .header-item-install .button-op {
      @include button-op-secondary;
    }
  }

  .header-item-contact {
    display: none;
  }

  .mobile-only {
    display: none;
  }

  @include media-breakpoint-up(uxxl) {
    font-size: 18px;

    .nav-input input {
      width: 280px;
    }
  }

  @include media-breakpoint-down(xxl) {
    .nav-widget {
      display: none;
    }

    &.header-scrolled {
      .header-item-signin {
        display: inherit;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    #header-dropdown-3,
    #header-dropdown-4,
    #header-dropdown-language {
      .dropdown-menu {
        position: absolute;
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    #header-dropdown-3,
    #header-dropdown-4 {
      .dropdown-col {
        min-width: auto;
      }
    }

    .dropdown-menu .nav-link {
      min-width: 240px;
      white-space: normal;
      word-break: normal;
      line-break: strict;
      hyphens: none;
    }
  }

  @media all and (max-width: $header-switch-mobile-breakpoint) {
    height: $header-mobile-height;
    padding: 0;

    button.header-search-toggle {
      color: var(--bs-header-color) !important;
    }

    .mobile-only {
      display: block;
    }

    .navbar-menu-mobile {
      display: block;

      .nav-item .nav-link {
        color: var(--bs-header-color);
      }
    }

    nav.navbar {
      padding: 0;

      .navbar-logo {
        flex-basis: 100%;
      }
    }

    .nav-toggle {
      display: flex;
    }

    .logo {
      width: 140px;

      .svg-text {
        fill: var(--bs-header-color);
      }

      .svg-icon {
        fill: #1A67A3;
      }
    }

    &, &.header-scrolled, &.header-white {
      background-color: var(--bs-header-bg-color);
    }

    .navbar-expand .navbar-collapse {
      display: none !important;
    }

  }

  @media all and (min-width: $header-switch-mobile-breakpoint) {
    .navbar .nav-item .dropdown-menu {
      display: block;
      max-height: 0;
      visibility: hidden;
      overflow: hidden;
      margin-top: 0;
    }

    .navbar .nav-item:hover .dropdown-menu, .navbar .nav-item:focus .dropdown-menu {
      max-height: 800px;
      visibility: visible;
    }
  }

  @media all and (max-width: 300px) {
    .logo {
      width: 100%;
      max-width: 166.85px;
    }

    .logo-container {
      margin-right: 8px;
    }
  }

}

@media all and (max-width: $header-switch-mobile-breakpoint) {

  body.menu-open {
    overflow: hidden;

    .header {
      .nav-hamburger {
        span:nth-child(1) {
          transform: rotate(45deg);
          top: -1px;
          left: -2px;
        }

        span:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        span:nth-child(3) {
          transform: rotate(-45deg);
          top: 16px;
          left: -2px;
        }
      }

      .dropdown-col {
        padding-left: 0;
      }

      #header-dropdown-language .dropdown-menu {
        padding: 8px;
      }

      .dropdown-toggle {
        padding-right: 32px !important;
      }

      .navbar-logo {
        padding-right: 47px;
      }

      .dropdown-toggle:after {
        position: absolute;
        top: 7px;
        right: 16px;
        font-size: 18px;
      }

      .dropdown-group-header {
        white-space: normal;
      }

      .navbar-menu {
        position: fixed;
        top: $header-mobile-height;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--bs-header-bg-color);
        flex-direction: column;
        overflow: auto;

        .dropdown-menu {
          transition: none !important;
          transition-delay: 0s !important;
        }

        .navbar-collapse {
          display: flex !important;
        }

        .navbar-nav {
          flex-direction: column;
          width: 100%;
        }

        .nav-item {
          padding: 0;
          margin: 0;

          > .nav-link {
            color: var(--bs-header-color);
            padding: 10px 15px;
            white-space: normal;
            width: 100%;
            text-align: left;

            &:hover {
              background-color: var(--bs-header-hover-bg-color);
            }
          }
        }

        .navbar-sections {
          border-top: 1px solid var(--bs-border-color);
          flex-basis: 100% !important;
          overflow: auto;
          align-items: flex-start;
          order: 1;

          .dropdown-group-header {
            margin-top: 7px;
            margin-bottom: 5px;
            font-size: 0.9em;
            padding-right: 15px;
            padding-left: 15px;
          }

          .dropdown-menu {
            position: relative;
            top: 0;
            border-radius: 0;
            border: none;
            box-shadow: none;
            padding: 5px 15px;
            margin: 0;

            .nav-link {
              font-size: 14px;
            }
          }

          .dropdown-row {
            flex-direction: column;
          }

        }

        .navbar-tools {
          border-top: 1px solid var(--bs-border-color);
          padding: 0;
          order: 0;

          .navbar-nav {
            flex-direction: row-reverse;
            justify-content: space-between;
            flex-wrap: nowrap;
            margin-left: 0 !important;
            padding: 0;
          }

          .header-search-toggle-item {
            display: none;
          }

          .nav-widget {
            width: 100%;
          }

          .nav-link {
            font-size: 14px;
          }

          .header-item-signin, .header-item-contact, .header-item-language {
            display: flex;
            align-content: center;
            justify-content: center;
            text-align: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
          }

          .header-item-signin {
            order: 1;
          }

          .header-item-contact {
            order: 2;
          }

          .header-item-language {
            order: 3;

            .dropdown-toggle {
              min-width: 90px;
            }

            .dropdown-toggle:after {
              top: unset;
              right: unset;
              display: inline-block;
              margin-top: -3px;
              margin-left: 4px;
            }

            .dropdown-menu {
              position: absolute !important;
              top: 44px; // 100%;
              left: 0 !important;
              right: auto;
              bottom: auto !important;
              margin-top: .125rem;
              margin-bottom: 0;
            }
          }

        }
      }

      .navbar-menu-mobile {
        display: none;
      }

      .dropdown-menu {
        max-height: unset !important;
        left: 0 !important;
        right: 0 !important;
        transform: unset !important;
        position: relative !important;
      }

      .dropdown-col .nav-item {
        min-width: unset;
      }
    }

  }
}

@media all and (max-width: 260px) {
  body.menu-open .header .navbar-menu .navbar-tools .navbar-nav {
    flex-wrap: wrap;

    li {
      flex: 1;
    }
  }
}

.header-placeholder {
  height: $header-height;

  @media all and (max-width: $header-switch-mobile-breakpoint) {
    height: $header-mobile-height;
  }
}

.header-search {
  background: var(--bs-body-bg);
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  max-height: 100%;
  z-index: 9;
  overflow-y: auto;

  .header-search-content {
    margin: 0 auto;
    max-width: 1440px;
    display: none;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
  }

  &.active {
    top: $header-height;
    height: calc(100% - #{$header-height});

    @media all and (max-width: $header-switch-mobile-breakpoint) {
      top: $header-mobile-height;
      height: calc(100% - #{$header-mobile-height});
    }

    .header-search-content {
      display: flex;
    }

    .search-sections {
      display: block;
    }
  }

  .header-search-toggle {
    position: absolute;
    right: -16px;
    top: -12px;
    color: var(--bs-body-color);
  }

}

.debug-responsive header {
  background-color: red !important;

  @include media-breakpoint-down(fourk) {
    background-color: steelblue !important;
  }

  @include media-breakpoint-down(uxxl) {
    background-color: indigo !important;
  }

  @include media-breakpoint-down(xxl) {
    background-color: yellow !important;
  }

  @include media-breakpoint-down(xl) {
    background-color: green !important;
  }

  @include media-breakpoint-down(lg) {
    background-color: blue !important;
  }

  @include media-breakpoint-down(md) {
    background-color: magenta !important;
  }

  @include media-breakpoint-down(sm) {
    background-color: lime !important;
  }

  @include media-breakpoint-down(xs) {
    background-color: darkcyan !important;
  }
}
