@mixin shape-top-padding() {
  padding-top: 300px;

  @include media-breakpoint-down(uxxl) {
    padding-top: 250px;
  }

  @include media-breakpoint-down(xxl) {
    padding-top: 200px;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 150px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }
}

@mixin shape-bottom-padding() {
  padding-bottom: 400px;

  @include media-breakpoint-down(fourk) {
    padding-bottom: 380px;
  }

  @media all and (max-width: 2800px) {
    padding-bottom: 300px;
  }

  @include media-breakpoint-down(uxxl) {
    padding-bottom: 250px;
  }

  @include media-breakpoint-down(xxl) {
    padding-bottom: 200px;
  }

  @include media-breakpoint-down(xl) {
    padding-bottom: 200px;
  }

  @include media-breakpoint-down(lg) {
    padding-bottom: 150px;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 100px;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 80px;
  }
}
