.list-blue-arrow {
  page-break-inside: avoid;
  break-inside: avoid-column;
  column-gap: 30px;

  li {
    list-style: none;
    line-height: 2rem;
    font-size: 1.125rem;
    break-inside: avoid;
    position: relative;

    &:before {
      content: "";
      border-color: transparent $primary;
      border-style: solid;
      border-width: .3rem 0 .3rem .4rem;
      display: inline-block;
      height: 0;
      width: 0;
      left: -1.25rem;
      top: 12px;
      position: absolute;
    }
  }
}

.list-green-checkmarks, .list-green-plusses {
  list-style-type: none;
  padding-left: 2px;

  li {
    position: relative;
    padding-left: 22px;
    margin-bottom: 10px;
    line-height: 1.5rem;
    font-size: 16px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      font-family: $font-family-icon;
      content: "\e806";
      color: #35C53F;
      font-size: 18px;
    }

    &.markdown-paragraph {
      p {
        display: inline;
        font-size: 16px;
        line-height: 1.5rem;
      }
    }
  }

  &.checkmark-circled {
    li:before {
      content: "\e807";
    }
  }
}

.list-green-plusses {
  li {
    &:before {
      content: "\e808";
    }
  }
}
